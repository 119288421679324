'use client';

import { Box, Container } from '@mui/material';
import dynamic from 'next/dynamic';
import React from 'react';
import { MainLeftNav } from 'src/components/modules/left-side-nav';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { Paths } from 'src/utils/constants/path';

const ContentfulBanner = dynamic(
  () =>
    import('src/components/modules/contentful-banner').then(
      (r) => r.ContentfulBanner,
    ),
  { ssr: false },
);

const LeftNavTemplate: React.FCC = (props) => {
  return (
    <Container sx={{ display: 'flex', gap: 5 }}>
      <Box
        sx={{
          flex: '0 0 284px',
          maxWidth: 284,
          [QueryMapping.mobile]: { display: 'none' },
        }}>
        <MainLeftNav />
      </Box>
      <Box
        sx={{
          flex: '1 1 auto',
          // magicial min-width
          // https://www.joshwcomeau.com/css/interactive-guide-to-flexbox/#the-minimum-size-gotcha-11
          minWidth: 0,
          [QueryMapping.mobile]: { overflow: 'hidden' },
        }}>
        <ContentfulBanner
          hideOnPath={[Paths.LobbyBetUTopPicks, Paths.MetaLiveSport]}
          sx={{ mb: 3 }}
        />
        {props.children}
      </Box>
    </Container>
  );
};

export default LeftNavTemplate;
